.maker-btn{
  display: table;
  margin: auto;
  margin-top: 5px;
}

.maker-btn button{
background: #0d6efd;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
  border: solid 1px rgb(0,0,0,0.4);
  color: #fff;
}

.maker-btn button:hover{
  filter:brightness(90%)
}
.maker-btn button:disabled{
  filter: brightness(75%)
}
