.custom-file {
  position: relative;
}
.aln-f {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 90%;
  transform: translateX(-50%);
}

.ntbl tbody tr td {
  font-size: 14px;
}
.ntbl thead tr th {
  position: relative;
  padding: 0px 10px;
}
.links {
  display: flex;
  flex-direction: column;

  position: fixed; /* Fixa o elemento na janela */
  top: 50%; /* Posiciona a 50% da altura da janela */
  left: 2%; /* (Opcional) Posiciona a 50% da largura da janela */
  transform: translate(
    -50%,
    -50%
  ); /* Centraliza o elemento exatamente no meio */
  background-color: #fff; /* Cor de fundo para visualização */
  color: #0d6efd; /* Cor do texto */
  padding: 10px; /* Espaçamento interno */
  border-radius: 8px; /* Bordas arredondadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para destaque */
}

.ctt {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: -30px;
  color: rgb(0, 0, 0, 0.5);
}
#p2 div,
span,
strong {
}
.aln9 {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.pvw {
  font-size: 12px;
}
.img-al3 {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha */
  gap: 10px; /* Espaçamento entre as imagens */
  justify-content: center; /* Alinha os itens horizontalmente ao centro */
  align-items: center; /* Alinha os itens verticalmente */
}

.stc-a {
  display: flex;
  justify-content: start;
}
.aln9 table {
  width: 90%;
}
.ntbl tbody tr td {
  position: relative;
  padding: 5px;
}
.aln-f div {
  text-align: center;
}
.b-modal {
  display: flex;
  justify-content: flex-end;
  border-top: solid 1px #ccc;
}
.b-modal button {
  position: relative;
  margin: 10px;
}
.al-c img {
  width: 180px;
  height: 65px;
  object-fit: contain;
}
.custom-file input {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.image-grid {
  display: table;
  margin: auto;
}
.image-item img {
  width: 300px;
  height: 300px;
  margin-top: 10px;
  border: solid 1px;
}
.prev-imgs {
  display: table;
  margin: auto;
}
.prev-imgs .pi {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prev-imgs img {
  width: 300px;
  height: 300px;
}
.prev-imgs .rmver {
  position: absolute;
  background: #ff0100;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  border: solid 1px rgb(0, 0, 0, 0.5);
}
.prev-imgs button:hover {
  filter: brightness(90%);
}
.prev-imgs img {
  display: table;
  margin: auto;
  margin-bottom: 5px;
  border: solid 1px;
}

.area-aar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.area-aar .imagem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 400px;
  border: solid 2px;
}

.area-aar .imagem .sem {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0d6efd;
  border: solid 1px rgb(0, 0, 0, 0.5);
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.area-aar .imagem .sem:hover {
  filter: brightness(90%);
}

.table-container {
  display: table;
  margin: auto;
  margin-top: 15px;

  border-collapse: collapse;
}

.table-header {
  background-color: #d9d9d9;
  border-bottom: solid 1px;
  text-align: center;
  padding: 8px;
}

.table-cell {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.table-cell-risk {
  text-align: center;
}

.gp-imgs {
  display: flex;
  flex-direction: row;
}

.gp-imgs .img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: dotted 2px;
}

.gp-imgs {
}

.gp-imgs .view {
  position: relative;
  border: solid 1px;
  margin-right: 10px;
}

.gp-imgs .view img {
  width: 120px;
  height: 120px;
}
.gp-imgs .view .ac {
  border-top: solid 1px;
}
.gp-imgs .view .ac .arb {
  float: right;
}

.gp-imgs .view .ac .b1 {
  background: #0d6efd;
  width: 25px;
}
.gp-imgs .view .ac .b2 {
  background: #dc3545;
  width: 25px;
}

.gp-imgs .view .ac button:hover {
  filter: brightness(90%);
}

.ipt-file2 {
  position: relative;
}

.ipt-file2 input {
  position: absolute;
  left: 0;
  height: 100%;
  width: 98%;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}

.area-8 {
  display: flex;
}

.area-8 .add {
  position: relative;
  margin-right: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: dotted 3px;
}
