.rgt td button:first-child{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    
  }

  .rgt tr{
   
  }
  
  .rgt td button:last-child{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }