.area-ptxt{
position: relative;
  margin-top: 10px;
  height: 200px;
  overflow-y: auto;
  border: solid 1px #ccc;
}

.area-ptxt ul {
  display: table;
  margin: auto;
}
.area-ptxt ul li{
  list-style-type:none;
  cursor: pointer;
  padding: 5px;
}

.area-ptxt ul li:hover{
  
  background: rgba(0,0,0,0.15)
}