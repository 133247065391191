.area-8 .preview .fx{
      position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: solid 1px rgb(0,0,0,0.5);
  border-radius: 100px;
  background: #dc3545;
  
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
}
.area-8 .preview .fx:hover{
    filter: brightness(90%);
}

.area-galeria{

  }
  
  .area-galeria .image{
    position: relative;
    width: 100px;
    height: 100px;
   margin-bottom: 15px;
  
  
  
  }
  .area-galeria .image button{
   position: absolute;
    
    width: 25px;
    height: 25px;
    cursor: pointer;
    border: solid 1px rgb(0,0,0,0.5);
    border-radius: 100px;
    background: #dc3545;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .area-galeria .image button:hover{
    filter: brightness(90%)
  }
  .area-galeria .image img{
    width: 100px;
    height: 100px;
    box-sizing:  border-box;
    border-radius: 5px;
    border: solid 1px rgb(0,0,0,0.5);
  }


  .area-galeria .image.slc{
    border: solid 1px red;
    height: 102px;
    border-radius: 5px;
  
  }