.bg-primary {
    background-color: #624bff !important;
  }
  .pb-21 {
    padding-bottom: 9.5rem !important;
  }
  .pt-10 {
    padding-top: 4rem !important;
  }
  
  .px-6 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .mt-n22 {
    margin-top: -10rem !important;
  }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)*.5);
    padding-left: calc(var(--bs-gutter-x)*.5);
    margin-right: auto;
    margin-left: auto;
  }
  *, ::after, ::before {
    box-sizing: border-box;
  }
  
  .align-items-center {
    align-items: center !important;
  }
  .justify-content-between {
    justify-content: space-between !important;
  }
  .d-flex {
    display: flex !important;
  }
  .btn-white {
    --bs-btn-color: #000;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 217,217,217;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #fff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #fff;
    --bs-btn-disabled-border-color: #fff;
  }
  

  .bt-add{
    position: relative;
    top: -27px;
  }

  .icon-shape svg{
    color: #282828;
  }