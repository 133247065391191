.diagram{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.tree-node {
  display: flex;
}
.node-children{
  
    margin-left: 15px;

  }


  .diagram .coluna{
    position: absolute;
    top: 0;
   width: 50px;
 
    margin-left: 200px;
    border: solid 1px;
  }
  .diagram .coluna .a{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    border-bottom: solid 1px;
  }
  
  .diagram .coluna .b{
      display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    border-bottom: solid 1px;
  }
  
  .tree-node{
    margin-bottom: 10px;
  }
  
  .diagram .coluna .c{
      display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    border-bottom: solid 1px;
  }
  
  .diagram .coluna .d{
      display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    border-bottom: solid 1px;
  }
  
  
  .diagram .coluna .e{
      display: flex;
    justify-content: center;
    align-items: center;
  height: 25px;
  
  }

  .tree-node .node-button.selected{
    color: red;
  }

  .a.selected{
    background: rgb(10,200,0);
    
  }
  .b.selected{
    background: rgb(10,200,0);
  }
  
  .c.selected{
    background: rgb(10,200,0);
    
  }
  
  .d.selected{
    background: rgb(10,200,0);
    
  }
  
  .e.selected{
    background: rgb(10,200,0);
    
  }
  
  
  
  
  