.area-8 .add input[type="file"]{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.open-glr{
  position: absolute;
  width: 100%;
  height: 100%;
}

.area-8 .preview{
  position: relative;
  margin-top: 15px;
  margin-right: 15px;
}

.area-8 .edit{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
background: #0d6efd;
border-radius: 100px;
width: 30px;
height: 30px;
border:solid 1px rgb(0,0,0,0.5);
  transform: translate(-50%, -50%);
}

.area-8 .edit:hover{
filter: brightness(90%)
}


.area-8 .preview img{
  
  height: 100px;
  width: 100px;
  border: solid 1px rgb(0,0,0,0.5);
}

.area-8 .edit input[type="file"]{
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 100px;
  opacity: 0;
  
}

