.select-search-container{
    position: absolute;
      margin-top: 10px;
      margin-left: -5px;
      width: 300px;
      background: #fff;
      border: solid 1px #ccc;
      border-radius: 4px;
      z-index: 1000;
    }
    .select-search-container .select-search-value input{
        position: relative;
        
        width: 100%;
        border-bottom: solid 1px #ccc;
        background-color: #fff;;
        padding: 5px;
      border-top-left-radius: 5px;
       border-top-right-radius: 5px;
        z-index: 1000;
      }
    .select-search-container .select-search-value input:focus{
      outline: none;
    }
    .select-search-container .select-search-select{
    
    }
    
    .select-search-container .select-search-select ul{
    position: relative;
      margin-left: -33px;
    
    }
    
    .select-search-container .select-search-select ul li{
      border-bottom: solid 1px #ccc;
      padding: 5px;
    
    }
    .select-search-container .select-search-select ul li:hover{
      background: rgb(0,0,0,0.1)
    }
    .select-search-container .select-search-select ul li:last-child{
      position: relative;
      margin-bottom: -13px;
      border-bottom: 0;
      
    }
    
    
    .slc-master{
        border:solid 1px #dee2e6;
        padding: 5px;
        border-radius: 5px;
        text-align: left;
        padding-left: 5px;
        color: rgb(0,0,0,0.7);
        cursor: pointer;
      }
      .slc-master span{
        position: relative;
        margin-left: 6px;
      }