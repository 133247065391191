.printer {
  padding: 30px;
}
.ctn {
}
#p1 {
  border: solid 1px;
}
.t1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
}
.t1 div {
  font-weight: 600;
}

.t2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
}

.t2 div {
  font-weight: 600;
}

.t3 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}
.t3 .img {
  display: table;
  margin: auto;

  width: 300px;
  height: 70px;
}
.t3 .img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.t3 img {
  width: 100%;
  height: 100px;
}
.t4 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}
.t4 div {
  font-weight: 600;
}

.t5 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}
.t5 .img {
  display: table;
  margin: auto;
  border: solid 1px;
  width: 300px;
  height: 70px;
}

.t6 {
  position: relative;
  margin-top: 25px;
}

.t6 div {
  position: relative;
  margin-left: 15px;
  margin-bottom: 15px;
  font-weight: 600;
}

.t7 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}

.t7 div {
  font-weight: 600;
}

.t7 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}

.t7 div {
  font-weight: 600;
}

.t8 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.t8 .qr {
  position: absolute;

  width: 80px;
  height: 80px;
  right: 70px;
  margin-top: -50px;
}
.t8 .qr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.t8 .qr img {
  width: 100%;
  height: 100%;
}

.t9 {
  display: table;
  margin: auto;
  width: 90%;
  margin-top: 5px;
}
.t9 div {
  font-size: 12px;
}

.t9 {
  display: table;
  margin: auto;
  width: 90%;
  margin-top: 5px;
}
.t9 div {
  font-size: 12px;
}

.t10 {
  position: relative;
  margin-top: 430px;
  text-align: center;
}

#p2 .area-mtp {
  border: solid 1px;
  margin: 10px;
}

#p2 .area-mtp {
  display: flex;
}
#p2 .area-mtp .area-a {
  border-right: solid 1px;
  width: 25%;
}
#p2 .area-mtp .area-a div {
  border-bottom: solid 1px;
}
#p2 .area-mtp .area-b {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: solid 1px;
  width: 50%;
}
#p2 .area-mtp .area-b div {
  font-weight: 600;
}
#p2 .area-mtp .area-a div:last-child {
  border-bottom: 0;
}

#p2 .smr {
  display: table;
  margin: auto;
  margin-bottom: 10px;
  width: 95%;
  padding: 0px;
}

#p2 .smr span {
  position: relative;
  margin-left: 20px;
}

#p2 .smr .fn {
  height: 30px;
}

.topic-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.topic-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.topic-left {
  flex-grow: 1;
}

.dots {
  flex-grow: 10;
  border-bottom: 2px dotted black;
  margin: 0 10px;
}

.topic-right {
  flex-shrink: 0;
}
#p2 .site {
  position: relative;
  margin-top: 300px;
  text-align: center;
}

#p2 .txt-p3 {
  padding: 10px;
}

#p2 .txt-p3 span {
  font-weight: 600;
}

.site-p2 {
  position: relative;
  text-align: center;
  margin-top: 800px;
}

.ac4 .ttl {
  position: relative;
  padding: 10px 0 0 0px;
  font-weight: 600;
}
.ac4 .area-normas {
  padding: 10px 0 0 40px;
}

.ac4 .area-normas .item {
  position: relative;
  margin-bottom: 20px;
}

.ac4 .site-2 {
  position: relative;
  text-align: center;
  margin-top: 600px;
}

.pg5 img {
  position: relative;

  display: table;
  margin: auto;
  width: 250px;
  height: 100px;
}

.pg5 .dados {
  position: relative;

  padding: 10px 0 0 60px;
}

.pg5 .dados .item {
  margin-top: 10px;
}
.pg5 .dados .item label {
  position: relative;
  margin-left: 10px;
}

.site-3 {
  position: relative;
  text-align: center;
  margin-top: 230px;
}

.site-4 {
  text-align: center;
  margin-top: 780px;
}

ul li {
  list-style: disc;
}

.centro {
  display: table;
  margin: auto;
}

.area-diag {
  display: flex;
  justify-content: center;
  align-items: center;
}
.area-diag .diagrama-old {
  position: absolute;
  margin-left: -300px;
  top: 85px;
}
.tree-node:last-of-type {
}
.area-diag .diagrama .tree-node {
  padding-left: 60px;
}

.alinha-f2 {
  position: relative;
  left: 10px;
}

.frm2 {
}
.ajusta-frm {
  display: table;
  margin: auto;
}
.ajusta-frm .texto {
  position: relative;
  display: table;
  margin: auto;
  left: 28%;
}
.frm2 {
  margin-left: 280px;
}
